<template>
  <div v-show="!isHidden" :class="CSSClasses" :style="computedStyle">
    <el-form-item
      ref="formItem"
      :prop="name"
      :label="aSelectSource.label"
      :class="elFormCssClasses"
      :style="elFormCss"
       :rules="[
        { required: _isRequired, message: $locale.main.message.required_field, trigger: 'blur' }
      ]"
    >
      <span slot="label" :class="labelCssClasses + ' xref_field_label'" :style="labelCss">
        <span :class="{ 'show_asterisk': requiredStyle === 'asterisk' }">{{ aSelectSource.label }}</span>
      </span>

      <treeselect
        ref="treeselect"
        v-model="localValue"
        :placeholder="placeholder || $locale.main.placeholder.select"
        class="custom_scrollbar xref_field"
        :options="computedOptions"
        :clearable="clearable"
        :load-options="loadOptions"
        :disabled="_isReadonly"
        :normalizer="normalizer"
        :multiple="multiple"
        :clear-value-text="treeXrefField.clearValueText"
        :no-children-text="treeXrefField.noChildrenText"
        :loading-text="$locale.main.message.loading"
        :no-options-text="treeXrefField.noOptionsText"
        :no-results-text="treeXrefField.noResultsText"
        :match-keys="treeXrefField.matchKeys"
        :value-consists-of="treeXrefField.valueConsistsOf"
        :disable-branch-nodes="!multiple"
        :flatten-search-results="false"
        :async="true"
        :clear-on-select="true"
        :disable-immediate-search="true"
        :cache-options="false"
        :append-to-body="true"
        :searchable="true"
        :limit="3"
        :data-type="'registry-selec-new'"
        :data-source-id="aSelectSource.registryId"
        :data-attribute="name || null"
        :data-alias="editorAlias || null"
        @open="onMenuOpen"
        @search-change="onSearch"
      >
      <template slot="before-list">
          <div v-show="showSearchTip" class="vue-treeselect__tip vue-treeselect__seacrh-promt-tip">
            <div class="vue-treeselect__icon-container">
              <span class="vue-treeselect__icon-warning"/>
            </div>
            <span class="vue-treeselect__tip-text vue-treeselect__seacrh-promt-tip-text">
              {{ treeXrefField.pressEnterToSearchText }}
            </span>
          </div>
        </template>

        <template slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }">
          <label v-if="!node.raw.isLoadingLabel" :title="node.label" :class="labelClassName" style="color: #606266;">
            {{ node.label }}
            <span
              v-if="node.raw.children_count"
              :class="countClassName"
            >({{ node.raw.children_count }})</span>
          </label>

          <div v-else class="vue-treeselect__loading-tip">
            <div class="vue-treeselect__icon-container">
              <span class="vue-treeselect__icon-loader"></span>
            </div>
            <span class="vue-treeselect__tip-text vue-treeselect__loading-tip-text">
              {{ $locale.main.message.loading }}
            </span>
          </div>
          </template>
        </treeselect>
      </el-form-item>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '@/components/InterfaceEditor/components/mixins'
import RequiredMixin from '@/components/InterfaceEditor/components/required_properties_mixin'

import InputLabel from '@/mixins/inputLabel.js'
import VisibleMixin from '@/components/InterfaceEditor/components/visible_properties_mixin'

// import Treeselect, { LOAD_CHILDREN_OPTIONS } from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Treeselect, { LOAD_CHILDREN_OPTIONS, ASYNC_SEARCH } from '@bingosoftnn/vue-treeselect'
import registryMixin from '../registry/registry_mixins'

import FilterBuilder, { EComponentTypes } from '@/components/InterfaceEditor/components/utils'
import { isNumeric } from '@/helpers'

export default {
  name: 'select-new',
  mixins: [mixin, RequiredMixin, InputLabel, VisibleMixin, registryMixin],
  components: {
    Treeselect
  },
  inject: {
    forceUpdateSettingsPanel: {
      default: () => () => {}
    },
    isEditor: {
      default: () => () => {}
    }
  },
  props: {
    registry_properties: {
      type: Array,
      frozen: true
    },
    value: {
      frozen: true
    },
    model: {
      frozen: true
    },
    readonly: {
      frozen: true
    },
    editorAlias: {
      type: String,
      description: 'alias'
    },
    name: {
      type: String,
      description: 'attribute',
      options: {
        removeSpaces: true
      }
    },
    defaultValue: {
      type: String,
      description: 'default_name',
      options: {
        tooltip: {
          show: true,
          content: 'select_new_user'
        }
      }
    },
    // Основные настройки выпадающего списка
    aSelectSource: {
      type: Object,
      editor: 'ASelectSource',
      default: function () {
        return {
          label: null,
          registryId: null,
          groupBy: null,
          fieldId: null
        }
      }
    },
    disabled: {
      type: Boolean,
      description: 'disabled',
      frozen: true
    },
    block: {
      type: Boolean,
      description: 'full_line'
    },
    wrapper: {
      type: Boolean,
      description: 'wrapper'
    },
    placeholder: {
      description: 'placeholder',
      type: String
    },
    recursiveGroup: {
      description: 'recursive_group',
      type: Boolean
    },
    filters: {
      type: Array,
      editor: 'Filters',
      options: {
        showXrefOption: true,
        showEqualsTypes: true
      }
    },
    stateId: {
      description: 'state',
      type: String
    },
    multiple: {
      type: Boolean,
      description: 'multiple'
    },
    clearable: {
      type: Boolean,
      description: 'clearable'
    },
    applyRestrictions: {
      type: Boolean,
      description: 'apply_restrictions',
      default: false
    }
  },
  data () {
    return {
      treeXrefField: {
        matchKeys: ['name', 'id'],
        valueConsistsOf: 'LEAF_PRIORITY',
        clearValueText: 'Очистить',
        noChildrenText: 'Нет данных',
        noOptionsText: 'Нет данных',
        noResultsText: 'Не найдено',
        pressEnterToSearchText: 'Для поиска нажмите Enter',
        notData: 'Нет данных'
      },
      firstOpen: false,
      listData: [],
      foundOptions: [],
      search: null,
      localValue: undefined,
      loaded: false,
      state: [],
      offset: 50,
      limit: 0,
      searchLoaded: false,
      loading: false
    }
  },
  mounted () {
    if (this.model) {
      this.localValue = this.model
      this.$emit('input', this.localValue)
    }
    if (this.value) {
      this.parseValue()
    } else if (this.defaultValue) {
      this.parseDefaultValue()
    }
    if (this.validationNeeded) {
      this.$refs.formItem.form.validateField(this.name)
    }
    let elemtInput = this.$refs?.treeselect.$el.querySelector('.vue-treeselect__input')
    if (elemtInput) {
      elemtInput.setAttribute('enterkeyhint', 'enter')
    }
  },
  watch: {
    value: {
      async handler (value, oldValue) {
        if (typeof oldValue !== 'undefined') {
        /*
          эксперимент с oldValue. Странный код исключает полное зависание системы для заполнение множественной ссылки выпадающим списком.
          компоненту выпадающий список задается атрибут множественной ссылки, мн. ссылка с этим же атрибутом находимтся в карточке
          компоненту выпадающий список задается одиночный выбор, мн. ссылка прячется. Получается выпадающий список как простая ссылка, но заполняет
          множественную ссылку. WTF?
        */
          return
        }
        if (value !== this.localValue) {
          this.parseValue()
          this.$emit('input', this.localValue || null)
        }
      }
    },
    localValue: {
      handler () {
        this.$emit('input', this.localValue || null)
        if (!this.$refs.treeselect.getValue() && !this.localValue) {
          this.localValue = null
          this.$emit('input', this.localValue)
        }
      }
    },
    dataFilters: {
      async handler () {
        if (this.loaded) {
          this.listData = await this.loadData(true)
        }
      }
    },
    forceUpdateSettingsPanel: {
      default: () => () => {}
    }
  },
  computed: {
    dataFilters () {
      const builder = new FilterBuilder(
        this.filters,
        this.getModel(),
        this.$store,
        EComponentTypes.xrefField
      )

      return builder.buildAsRegistryService()
    },
    computedStyle () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (this.width && !this.block) {
        css += ';width:' + this.width
      }
      if (!this.block) {
        css += `;display: inline-block; width:${this.width || '200px'}`
      }
      if (this.wrapper) {
        css += ';display: block;'
      }

      return css
    },
    showSearchTip () {
      return this.search && !this.searchLoading && !this.searchLoaded
    },
    computedOptions () {
      if (this.searchLoaded) {
        return this.foundOptions
      } else if (!this.firstOpen) {
        return this.state
      } else {
        return this.listData
      }
    }
  },
  methods: {
    async parseValue () {
      let parsed = this.value
      try {
        parsed = JSON.parse(parsed)
      } catch (e) {}
      if (Array.isArray(parsed)) {
        if (this.multiple) {
          this.localValue = parsed.map((item) => item.id ? item.id : item)
        } else {
          this.localValue = parsed.map((item) => item.id ? item.id : item)[0]
        }
      } else {
        if (this.multiple) {
          if (isNumeric(parsed)) {
            this.localValue = [parseInt(parsed)]
          }
        } else {
          // проверка, что значение возможно дата
          // parseInt('2021-11-18') -> съедает значение (2021)
          if (typeof parsed === 'string' && RegExp(/\d{4}-\d{2}-\d{2}$/).test(parsed)) {
            this.localValue = parsed
          } else {
            if (isNumeric(parsed)) {
              this.localValue = parseInt(parsed)
            }
          }
        }
      }
      if (!this.firstOpen) {
        this.listData = await this.loadData(true, null)
        this.firstOpen = true
      }
    },
    async parseDefaultValue () {
      let value = this.defaultValue
      // несколько значений по умолчанию
      if (this.defaultValue.includes(',')) {
        value = this.defaultValue.split(',')
      }
      if (value === '{{user_id}}') {
        value = this.$store.getters['Authorization/userId']
      }
      if (value === '{{role_id}}') {
        value = this.$store.getters['Authorization/roleId']
      }
      if (/user.attr_[0-9]+_/i.test(value)) {
        value = await this.$store.getters['Authorization/userAttributeData'](value.match(/attr_[0-9]+_/gi)[0])
      }
      try {
        value = JSON.parse(value)
      } catch (e) {
      }
      if (typeof value !== 'number' && Array.isArray(value)) {
        let temp = []
        if (value.length > 0) {
          value.forEach((item) => {
            temp.push(parseInt(item.id || item))
          })
        }
        if (temp.length > 0) {
          this.localValue = this.multiple ? temp : parseInt(temp[0])
        }
      } else {
        this.localValue = (this.multiple ? [parseInt(value)] : parseInt(value))
      }
      if (!this.firstOpen) {
        this.listData = await this.loadData(true, null)
      }
      this.firstOpen = true
      this.$emit('input', this.localValue)
    },
    normalizer (node) {
      return {
        id: node.id,
        label: this.getNodeLabel(node)
      }
    },
    getNodeLabel (node) {
      let name = (node.name || node.id)
      if (!this.alias) {
        return name
      }
      let label = this.alias
      label = label.replace(`{{name}}`, name)
      let attributes = this.alias.match(/\{{(.*?)\}}/g) || []
      attributes.forEach((attribute) => {
        attribute = attribute.replace('{{', '').replace('}}', '')
        label = label.replace(`{{${attribute}}}`, node[attribute] ?? '')
      })

      return label
    },
    async loadOptions ({ action, parentNode, callback }) {
      if (action === LOAD_CHILDREN_OPTIONS) {
        parentNode.children = await this.loadData(true, parentNode.id)
        if (parentNode.children.length) {
          parentNode.isDisabled = false
        }
        callback()
      } else if (action === ASYNC_SEARCH) {
        if (this.recursiveGroup) {
          this.searchLoaded = false
          this.searchLoading = true
          this.foundOptions = await this.loadData(true)
          callback(null, this.foundOptions)
          this.searchLoading = false
          this.searchLoaded = true
          return
        }
        this.foundOptions = []
        const myPromise = new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve()
          }, 300)
        })
        await myPromise
        this.searchLoaded = false
        this.searchLoading = true
        for (let el of this.state) {
          if (el.name && el.name.toLowerCase().search(this.search.toLowerCase()) !== -1) {
            this.foundOptions.push(el)
          }
        }
        callback(null, this.foundOptions)
        this.searchLoading = false
        this.searchLoaded = true
      }
    },
    onSearch (value) {
      this.search = value
      this.searchLoaded = false
      // this.offset = 0
      this.foundOptions = []
    },
    async onMenuOpen () {
      if (!this.firstOpen) {
        this.firstOpen = true
        this.listData = await this.loadData(true, null)
      }
      this.$nextTick(() => {
        const menu = this.$refs.treeselect.getMenu()
        const magicNumber = 5
        menu.addEventListener('scroll', async () => {
          const hasReachedEnd = menu.scrollHeight - menu.scrollTop <= (menu.clientHeight + magicNumber)
          if (hasReachedEnd && !this.search && !this.aSelectSource.groupBy) {
            this.limit = 0
            this.offset += 50
            let loadArr = this.state.slice(this.limit, this.offset)
            this.listData = loadArr
          }
        })
      })
    },
    getParameters (parentNode = null) {
      let answer = []
      if (this.search) {
        let valueByInput = this.$refs.treeselect.$el.querySelector('.vue-treeselect__input').value
        answer.push(`search=${encodeURIComponent(valueByInput)}`)
      } else {
        if (this.aSelectSource.groupBy) {
          answer.push(`group_by=${this.aSelectSource.groupBy}`)
          if (parentNode) {
            answer.push(`node=${parentNode}`)
          }
          if (this.recursiveGroup) {
            answer.push(`recursive=1`)
          }
        }
      }
      if (this.stateId) {
        answer.push(`state_id=${this.stateId}`)
      }
      this.dataFilters.forEach((filter, index) => {
        answer.push(`filter[${index}]=${filter}`)
      })
      if (this.applyRestrictions) {
        answer.push(`apply_restrictions=1`)
      }
      return answer
    },
    async loadData (force = false, parentNode = null, isRefresh = null) {
      if (!this.loaded || force) {
        this.loading = true
        if (this.aSelectSource.fieldId && this.aSelectSource.groupBy) {
          let response = await this.$http.get(`${this.$config.api}/registryservice/xref/${this.aSelectSource.fieldId}/data?${this.getParameters(parentNode).join('&')}`)
            .catch(() => {
              this.loading = false
            })
          this.loading = false
          this.loaded = true
          if (this.aSelectSource.groupBy) {
            return (response.data || []).map((item) => {
              item.children = (item.children_count > 0 || (!this.recursiveGroup && !parentNode && !this.search)) ? null : false
              item.children = (item.children_count > 0 || (!parentNode && !this.search)) ? null : false
              if (item.children === null) {
                item.isDisabled = true
              }
              return item
            })
          } else {
            return response.data
          }
        }
        if (this.aSelectSource.registryId && this.aSelectSource.fieldId) {
          let answer = await this.$http.get(`${this.$config.api}/registryservice/xref/?registry_id=${this.aSelectSource.registryId}&attribute_id=${this.aSelectSource.fieldId}&${this.getParameters().join('&')}`)
            .catch(() => {
              this.loading = false
            })
          this.loaded = true
          this.state = answer.data
          // пока нет бека
          if (answer.data.length > 50) {
            return answer.data.slice(this.limit, this.offset)
          } else {
            return answer.data
          }
        }
      }

      if (isRefresh) {
        await this.parseDefaultValue()
      }

      return this.listData
    }
  }
}
</script>

<style>
/* Отступ из-за vue-treeselect @TODO vue-treeselect убрать побочный эффект влияния на положение компонента в контейнере */
.xref_field_label {
    position: relative;
    top: 13px;
}
/* Отступ из-за vue-treeselect @TODO vue-treeselect убрать побочный эффект влияния на положение компонента в контейнере */
.xref_field.vue-treeselect {
    display: inline-block;
    top: 16px;
}
.xref_field .vue-treeselect__control {
    height: 40px;
}

.xref_field .vue-treeselect__single-value {
    color: #606266;
}

.xref_field .vue-treeselect__input {
    color: #606266;
    vertical-align: baseline;
}

.xref_field .vue-treeselect__placeholder {
    line-height: 40px;
}

.xref_field .vue-treeselect__value-container {
    line-height: 20px;
}
.vue-treeselect__portal-target {
    z-index: 10000 !important;
}
</style>
